import React from "react"
import { graphql } from "gatsby"
import SEO from "../utils/seo"
import styled from "styled-components"
import Img from "gatsby-image"
import Icon from "../utils/icon"
import Heading from "../utils/heading"
import CMS from "../utils/cms"

import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import SocialSharing from "../components/socialSharing/socialSharing"

const Wrapper = styled.div`

  ${props => props.theme.wrappersContainers.primaryWrapper};
  ${props => props.theme.wrappersContainers.primaryContainer};

`

const Details = styled.div`
  @media ${props => props.theme.mq.md} {
    display:flex;
    justify-content: space-between;
  }

  .title{
    ${props => props.theme.fonts.h3}
  }
  
  time{
    margin: auto 0;
    ${props => props.theme.fonts.smallerFontSize};
  }

`

const ImgWrapper = styled.div`
  position: relative; 
  margin:1rem 0;
    @media ${props => props.theme.mq.md} {
      margin:0 0 2rem 0; 
    }

  .socialSharingWrapper{
    position: absolute;
    bottom:0;
    right:0;
    z-index: 2;
    background-color: white;
    padding: 0.5rem 1rem 0.2rem 1rem;
    @media ${props => props.theme.mq.sm} {
      padding: 1rem 2rem 0.5rem 2rem;
    }
  }

  .gatsby-image-wrapper{
    height:14rem;
      @media ${props => props.theme.mq.sm} {
        height:50vh;
      }
      @media ${props => props.theme.mq.md} {
        height:70vh;
      }
      @media ${props => props.theme.mq.lg} {
        height:80vh;
      }
  }
`
const Back = styled.div.attrs({
  className: "dontPrint",
})`
  margin-top:2rem;
  padding:2rem 0;
  border-top:1px solid ${props => props.theme.colors.lightGrey};

  .svgWrapper{
    transform: rotate(180deg);
    display:inline-block;
    margin-right:0.5rem;
    stroke:${props => props.theme.colors.primary};
    fill: ${props => props.theme.colors.primary};
  }

  a{
    color: ${props => props.theme.colors.primaryFontColor};
    text-decoration: none;
    ${props => props.theme.fonts.smallerFontSize};
  }
`

const Post = ({ data }) => {

  return (

    <>

      <SEO
        title={data.wpPost.seo.title || data.wpPost.title}
        description={
          data.wpPost.seo.metaDesc ||
          data.wpPost.excerpt ||
          data.wpPost.description
        }
        image={data?.wpPost?.featuredImage?.node.localFile.childImageSharp.fluid}
        canonical={data.wpPost.uri}
        article={true}
      />

      <Wrapper>

        <Details>
          <Heading type="title" as="h1" color={props => props.theme.colors.secondary}>
            {data.wpPost.title}
          </Heading>

          <time>{data.wpPost.date}</time>
        </Details>

        <ImgWrapper>
          <SocialSharing title={data.wpPost.title} />
          <Img fluid={data.wpPost.featuredImage?.node.localFile.childImageSharp.fluid} />
        </ImgWrapper>

      </Wrapper>

      <Breadcrumbs currentPage={data.wpPost.title} />

      <Wrapper>
        {data.wpPost.content &&
          <div dangerouslySetInnerHTML={{ __html: data.wpPost.content }} />
        }
      </Wrapper>

      

      <CMS>{data.wpPost.content_module_system.modules}</CMS>

      <Wrapper>
        <Back>
          <a href={"/blog"} title="Back">
            <Icon name="nav-item-arrow" width="2rem" />
            Back to blog listings
          </a>
        </Back>
      </Wrapper>

    </>

  )

}

export default Post

export const pageQuery = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      ...postFragments
      content_module_system {
        modules {
          ...headerBlockFragmentsPost
          ...contentFragmentsPost
          ...sideBySideFragmentsPost
          ...navigationSliderFragmentsPost
          ...propSelectFragmentsPost
        }
      }
    }
  }
`
